import http from '../clients/Axios'
export default class LaporanPiutangUsahaService {
  async getLaporanDetail(end_date, pelanggan_id) {
    const res = await http.get(
      `/report/piutang/detail?filter[periode_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}`
    )
    return res
  }

  async exportLaporanDetail(end_date, pelanggan_id, salesman_id, area_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (salesman_id) {
      params.append('filter[salesman_id]', salesman_id)
    }
    if (area_id) {
      params.append('filter[area_id]', area_id)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/detail/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async printLaporanDetail(end_date, pelanggan_id, salesman_id, area_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (salesman_id) {
      params.append('filter[salesman_id]', salesman_id)
    }
    if (area_id) {
      params.append('filter[area_id]', area_id)
    }
    return http({
      url: `/report/piutang/detail/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanRangkuman(end_date, pelanggan_id) {
    const res = await http.get(
      `/report/piutang/rangkuman?filter[periode_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}`
    )
    return res
  }

  async exportExcelLaporanRangkuman(end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/rangkuman/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanRangkuman(end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    return http({
      url: `/report/piutang/rangkuman/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanPerPelanggan(end_date, pelanggan_id) {
    const res = await http.get(
      `/report/piutang/perpelanggan?filter[periode_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}`
    )
    return res
  }

  async exportLaporanPerPelanggan(end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    } else {
      params.append('filter[pelanggan_id]', 0)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    return http({
      url: `/report/piutang/pelanggan/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanPerPelanggan(end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    } else {
      params.append('filter[pelanggan_id]', 0)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/pelanggan/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanMutasi(
    start_date,
    end_date,
    pelanggan_id,
    kelompok_id,
    type
  ) {
    const res = await http.get(
      `/report/piutang/mutasi?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}&filter[pelanggan_kelompok_id]=${kelompok_id}&filter[type]=${type}`
    )
    return res
  }

  async exportExcelLaporanMutasi(
    start_date,
    end_date,
    pelanggan_id,
    kelompok_id,
    type
  ) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (kelompok_id) {
      params.append('filter[kelompok_id]', kelompok_id)
    }
    if (type) {
      params.append('filter[type]', type)
    }

    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/mutasi/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanMutasi(
    start_date,
    end_date,
    pelanggan_id,
    kelompok_id,
    type
  ) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (kelompok_id) {
      params.append('filter[kelompok_id]', kelompok_id)
    }
    if (type) {
      params.append('filter[type]', type)
    }
    return http({
      url: `/report/piutang/mutasi/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanUmur(end_date, salesman_id, type) {
    const res = await http.get(
      `/report/piutang/umur?filter[periode_to]=${end_date}&filter[salesman_id]=${salesman_id}&filter[type]=${type}`
    )
    return res
  }

  async exportLaporanUmur(end_date, salesman_id, type) {
    const params = new URLSearchParams()
    if (salesman_id) {
      params.append('filter[salesman_id]', salesman_id)
    } else {
      params.append('filter[salesman_id]', '0')
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (type) {
      params.append('filter[type]', type)
    }
    return http({
      url: `/report/piutang/umur/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanUmur(end_date, salesman_id, type) {
    const params = new URLSearchParams()
    if (salesman_id) {
      params.append('filter[salesman_id]', salesman_id)
    } else {
      params.append('filter[salesman_id]', '0')
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (type) {
      params.append('filter[type]', type)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/umur/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanPembayaran(start_date, end_date) {
    const res = await http.get(
      `/report/piutang/pembayaran?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}`
    )
    return res
  }

  async exportLaporanPembayaran(start_date, end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    return http({
      url: `/report/piutang/pembayaran/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanPembayaran(start_date, end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/pembayaran/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async getLaporanPembayaranPerPelanggan(
    start_date,
    end_date,
    pelanggan_id,
    type
  ) {
    const res = await http.get(
      `/report/piutang/pembayaran/perpelanggan?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}&filter[type]=${type}`
    )
    return res
  }

  async exportLaporanPembayaranPerPelanggan(
    start_date,
    end_date,
    pelanggan_id,
    type
  ) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (type) {
      params.append('filter[type]', type)
    }
    return http({
      url: `/report/piutang/pembayaran_pelanggan/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanPembayaranPerPelanggan(
    start_date,
    end_date,
    pelanggan_id,
    type
  ) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (type) {
      params.append('filter[type]', type)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/pembayaran_pelanggan/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanPerSalesman(end_date, salesman_id) {
    const params = new URLSearchParams()
    if (salesman_id) {
      params.append('filter[salesman_id]', salesman_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/salesman/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanPerSalesman(end_date, salesman_id) {
    const params = new URLSearchParams()
    if (salesman_id) {
      params.append('filter[salesman_id]', salesman_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    return http({
      url: `/report/piutang/salesman/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanPerAreaSalesman(end_date, salesman_id, area_id) {
    const params = new URLSearchParams()
    params.append('filter[salesman_id]', salesman_id)
    params.append('filter[area_id]', area_id)
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    return http({
      url: `/report/piutang/salesman/area/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanPerAreaSalesman(end_date, salesman_id, area_id) {
    const params = new URLSearchParams()
    params.append('filter[salesman_id]', salesman_id)
    params.append('filter[area_id]', area_id)
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/salesman/area/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanPiutangPenjualan(start_date, end_date, type) {
    const params = new URLSearchParams()
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (type) {
      params.append('filter[type]', type)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/penjualan/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanPiutangPenjualan(start_date, end_date, type) {
    const params = new URLSearchParams()
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    if (type) {
      params.append('filter[type]', type)
    }
    return http({
      url: `/report/piutang/penjualan/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportExcelLaporanKartuStok(end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/report/piutang/kartu/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }

  async exportLaporanKartuStok(end_date, pelanggan_id) {
    const params = new URLSearchParams()
    if (pelanggan_id) {
      params.append('filter[pelanggan_id]', pelanggan_id)
    }
    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }
    return http({
      url: `/report/piutang/kartu/print`,
      method: 'GET',
      responseType: 'blob',
      params: params,
    })
  }
}
